/** @jsx jsx */
import { jsx } from "theme-ui"
import Container from "./container"
import Row from "./row"
import Column from "./column"
import Text from "./text"
import PageLink from "./page-link"
import LinkPreview from "./link-preview"
import CTA from "./cta"

const ToursEventsNews = ({ events, news, customStyle }) => {
  const lastEvents = events
    .map(event => {
      return {
        ...event,
        startDate: new Date(event.start.utc),
        endDate: new Date(event.end.utc),
      }
    })
    .sort((a, b) => a.date - b.date)
    .slice(0, 4)
  const lastArticles = news
    .sort((a, b) => {
      const dateA = new Date(a.date)
      const dateB = new Date(b.date)
      return dateB - dateA
    })
    .slice(0, 4)
  const formatStartEndTime = (start, end) => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const toAMPM = date => {
      const hours = date.getHours()
      let hoursFormatted =
        hours > 12 ? (hours - 12).toString() : hours.toString()
      let minutesFormatted = date.getMinutes().toString()
      if (minutesFormatted.length === 1)
        minutesFormatted = "0" + minutesFormatted
      const ampm = hours >= 12 ? "PM" : "AM"
      return `${hoursFormatted}:${minutesFormatted} ${ampm}`
    }
    return `${toAMPM(startDate)} - ${toAMPM(endDate)} PDT`
  }
  const formatDate = date => {
    const format = date.toDateString().split(" ")
    return `${format[0]}, ${format[1]} ${format[2]}, ${format[3]}`
  }
  const renderCalendar = date => {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    let day = date.getDate().toString()
    if (day.length === 1) day = "0" + day
    return (
      <div
        sx={{
          width: 100,
          height: 100,
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          justifyContent: "center",
          border: "1px solid #ddd",
          borderRadius: 5,
          mr: 3,
        }}
      >
        <Text sx={{ fontSize: 4 }}>
          {months[date.getMonth()].toUpperCase().slice(0, 3)}
        </Text>
        <Text sx={{ fontSize: 6, fontWeight: "bolder", lineHeight: "100%" }}>
          {day}
        </Text>
      </div>
    )
  }
  const renderEvent = event => {
    return (
      <PageLink
        type="external"
        link={event.url}
        customStyle={{
          color: "text",
          ":hover": {
            color: "primary",
          },
          my: 10,
          pr: 0,
        }}
      >
        <Row>
          {renderCalendar(event.startDate)}
          <Column>
            <Text
              customStyle={{
                fontWeight: 500,
                fontSize: 3,
                color: "inherit",
                lineHeight: 1.3,
                mb: 1,
              }}
            >
              {event.name.text}
            </Text>
            <Text customStyle={{ color: "text" }}>
              {formatDate(event.startDate)}
            </Text>
            <Text>
              {formatStartEndTime(event.start.local, event.end.local)}
            </Text>
          </Column>
        </Row>
      </PageLink>
    )
  }
  const renderArticle = article => {
    const formatDate = date => {
      const format = new Date(date).toDateString().split(" ")
      return `${format[1]} ${format[2]}, ${format[3]}`
    }
    return (
      <div
        sx={{
          overflow: "hidden",
          my: 10,
        }}
      >
        <LinkPreview
          link={article.link}
          title={article.title}
          image={article.image}
          customStyle={{ mb: 0 }}
          containerStyle={{
            display: "flex",
            flexDirection: "row-reverse",
            pl: 2,
          }}
          imageStyle={{
            width: 100,
            height: 100,
            objectFit: "cover",
            borderRadius: 5,
            ml: "3px",
            mr: 3,
          }}
          titleStyle={{
            fontWeight: 500,
            lineHeight: 1.3,
            fontSize: 3,
          }}
          subtitle={`${
            article.publicationTitle ? article.publicationTitle + " - " : ""
          }${formatDate(article.date)}`}
        />
      </div>
    )
  }
  return (
    <Container customStyle={{ ...customStyle }}>
      <Row>
        {/* <Column size={[12, 12, 6, 5]} customStyle={{ pr: [0, 0, 0, 7] }}>
          <Text type="h2" customStyle={{ mb: 4 }}>
            Tours & Events
          </Text>
          {lastEvents.map(event => renderEvent(event))}
          <CTA
            link={"/tours-and-events"}
            type="secondary"
            linkType="internal"
            customStyle={{
              maxWidth: 200,
              mt: 5,
            }}
          >
            View All Events
          </CTA>
        </Column> */}
        <Column
          size={[12, 12, 6, 7]}
          customStyle={{ mt: [6, 6, 0], pr: [0, 0, 0, 100, 200] }}
        >
          <Text type="h2" customStyle={{ mb: 4 }}>
            In the Media
          </Text>
          {lastArticles.map(article => renderArticle(article))}
          <CTA
            link={"/in-the-news"}
            type="secondary"
            linkType="internal"
            customStyle={{
              maxWidth: 200,
              mt: 5,
            }}
          >
            View All Features
          </CTA>
        </Column>
      </Row>
    </Container>
  )
}
export default ToursEventsNews
