/** @jsx jsx */
import { jsx } from "theme-ui"
import Text from "./text"
import Row from "./row"
import Column from "./column"
import PageLink from "./page-link"

const LinkPreview = ({
  link,
  title,
  subtitle,
  image,
  type,
  linkType,
  customStyle,
  containerStyle,
  imageStyle,
  titleStyle,
}) => {
  return (
    <PageLink
      link={link || "/"}
      customStyle={{
        textDecoration: "none",
        mb: 3,
        pr: 0,
        display: "block",
        color: "text",
        ":hover": { color: "primary" },
        ...customStyle,
      }}
      type={linkType}
    >
      <Row customStyle={{ ...containerStyle }}>
        <Column customStyle={{ flexGrow: 2 }}>
          <Text
            type="h3"
            customStyle={{
              fontSize: 22,
              pb: 2,
              pr: 0,
              color: "inherit",
              fontWeight: "500",
              ...titleStyle,
            }}
          >
            {title}
          </Text>
          <Text customStyle={{ color: "#aaa" }}>{subtitle}</Text>
        </Column>
        {type !== "secondary" &&
          (image ? (
            <img
              src={image.file.url}
              sx={{
                width: ["115px", "230px"],
                height: ["65px", "130px"],
                objectFit: "cover",
                ...imageStyle,
              }}
              alt={image.description}
            />
          ) : (
            <div sx={{ width: ["115px", "230px"] }}></div>
          ))}
      </Row>
    </PageLink>
  )
}
export default LinkPreview
