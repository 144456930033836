import React from "react"
import { useIsClient } from "./use-is-client"

export const useIsTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = React.useState(false)
  const isClient = useIsClient()
  const getIsTouchDevice = () => {
    var prefixes = " -webkit- -moz- -o- -ms- ".split(" ")

    var mq = function(query) {
      return window.matchMedia(query).matches
    }

    if ("ontouchstart" in window) {
      return true
    }
    var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("")
    return mq(query)
  }
  React.useEffect(() => {
    if (isClient) setIsTouchDevice(getIsTouchDevice())
  }, [isClient])

  return isTouchDevice
}
