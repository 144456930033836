/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { Flex } from "@theme-ui/components"

import { useScrollYPosition } from "../hooks/use-scroll-position"

const StickyContainer = ({ customStyle, children, height }) => {
  const el = React.useRef(null)
  const scrollY = useScrollYPosition()
  const minHeight = el && el.current ? el.current.offsetTop : 0
  const maxHeight = minHeight + height
  const hidingHeight = maxHeight - window.outerHeight
  return (
    <div sx={{ height: maxHeight - minHeight, position: "relative" }} ref={el}>
      <Flex
        sx={{
          ...customStyle,
          position:
            scrollY > minHeight && scrollY < hidingHeight
              ? "fixed"
              : "absolute",
          background: "white",
          top:
            scrollY > hidingHeight
              ? maxHeight - minHeight - window.outerHeight
              : 0,
          height: window.outerHeight + 2,
          width: "100%",
          zIndex: 9999,
        }}
      >
        {children}
      </Flex>
    </div>
  )
}

export default StickyContainer
