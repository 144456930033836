/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
// import loadable from '@loadable/component'
import { graphql } from "gatsby"
import chunk from "lodash/chunk"
import kebabCase from "lodash/kebabCase"
import { Flex } from "@theme-ui/components"
import get from "lodash/get"
import { renderLineBreak, findBySlug } from "../helpers"

import StickyContainer from "../components/sticky-container"
// import Carousel from "../components/carousel"


import Column from "../components/column"
import Container from "../components/container"
import ContentCard from "../components/content-card"
import CTA from "../components/cta"
import FeaturedProjects from "../components/featured-projects"
// import Hero from "../components/hero"

import Page from "../components/page"
import ProjectCard from "../components/project-card"
import ProjectUnique from "../components/project-unique"
import Row from "../components/row"
import Text from "../components/text"
import Quote from "../components/design-partner/quote"

import { useIsTouchDevice } from "../hooks/use-is-touch-device"
import { useIsClient } from "../hooks/use-is-client"
import { useMobileImageSelector } from "../hooks/use-mobile-image-selector"
import ToursEventsNews from "../components/tours-events-news"
import ScrollAnimation from "../components/scroll-animation"
import { paramsCheck } from "../functions/functions"
import Hero from "../components/hero"
import Carousel from "../components/carousel"
// const Carousel = loadable(() => import('../components/carousel'))
  // const Hero = loadable(() => import('../components/hero'))
const IndexPage = ({ data }) => {
  const fullWidthList = [true, true, true]
  const isTouch = useIsTouchDevice()
  const isClient = useIsClient()
  const mobileImageSelector = useMobileImageSelector()
  React.useEffect(() => {
    document.head.innerHTML +=
      '<meta name="description" content="Fast, high-quality fabrication of panelized and modular components for multifamily housing and single-family home communities.">'
  }, [])
  const renderHeroSection = () => {
    let heroBlade = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "home-hero-module-1",
    })
    paramsCheck()

    return (
      <Container fullWidth={fullWidthList}>
        <Row noGutters={fullWidthList}>
          <Column noGutters={fullWidthList}>
            {!isClient || isTouch ? (
              <Carousel>
                {activeIndex =>
                  heroBlade.contentCards.map((card, index) => (
                    <Hero
                      key={kebabCase(card.title)}
                      animateManual={true}
                      animateShow={activeIndex === index}
                      bodyStyle={{
                        ":first-of-type": {
                          mt: theme => [
                            theme.spacing.vertical.sm,
                            theme.spacing.vertical.sm,
                            theme.spacing.vertical.md,
                          ],
                        },
                        mt: 0,
                        mb: 0,
                        fontSize:17
                      }}
                      background={{
                        type: card.video ? "video" : "image",
                        source: {
                          desktopImage: card.desktopImage,
                          ...(card.mobileImage && {
                            mobileImage: card.mobileImage,
                          }),
                          ...(card.video && { video: card.video }),
                        },
                      }}
                      richText={card.description}
                      title={card.title}
                      type="primary"
                      verticalAlign={"center"}
                    />
                  ))
                }
              </Carousel>
            ) : (
              heroBlade.contentCards.map((card, index) =>
                index === 0 ? (
                  <Hero
                    animateOnScroll={true}
                    customStyle={{
                      height: theme =>
                        `calc(100vh - ${theme.sizes.header.height}px)`,
                      width: "100%",
                    }}
                    bodyStyle={{
                      ":first-of-type": {
                        mt: theme => [
                          theme.spacing.vertical.sm,
                          theme.spacing.vertical.sm,
                          theme.spacing.vertical.md,
                        ],
                      },
                      mt: 0,
                      mb: 0,
                      fontSize:17
                    }}
                    key={kebabCase(card.title)}
                    background={
                      card.video
                        ? {
                            type: "video",
                            source: {
                              video: card.video,
                              desktopImage: card.desktopImage,
                              ...(card.mobileImage && {
                                mobileImage: card.mobileImage,
                              }),
                            },
                          }
                        : {
                            type: "image",
                            source: {
                              desktopImage: card.desktopImage,
                              ...(card.mobileImage && {
                                mobileImage: card.mobileImage,
                              }),
                            },
                          }
                    }
                    richText={card.description}
                    title={card.title}
                    type="primary"
                    verticalAlign={"center"}
                    videoLink={card.ctaDestination?.ctaDestination}
                    videoTitle={card.ctaTitle}
                  />
                ) : (
                  <div sx={{ height: 1500 }} key={index}>
                    <Hero
                      animateOnScroll={true}
                      customStyle={{
                        height: "100vh",
                        width: "100%",
                        position: "sticky",
                        top: 0,
                      }}
                      bodyStyle={{
                        ":first-of-type": {
                          mt: theme => [
                            theme.spacing.vertical.sm,
                            theme.spacing.vertical.sm,
                            theme.spacing.vertical.md,
                          ],
                        },
                        mt: 0,
                        mb: 0,
                        fontSize:17
                      }}
                      key={kebabCase(card.title)}
                      background={
                        card.video
                          ? {
                              type: "video",
                              source: {
                                video: card.video,
                                poster: card.desktopImage,
                              },
                            }
                          : {
                              type: "image",
                              source: {
                                desktopImage: card.desktopImage,
                                ...(card.mobileImage && {
                                  mobileImage: card.mobileImage,
                                }),
                              },
                            }
                      }
                      richText={card.description}
                      title={card.title}
                      type="primary"
                      verticalAlign={"center"}
                      videoLink={card.ctaDestination?.ctaDestination}
                      videoTitle={card.ctaTitle}
                    />
                  </div>
                )
              )
            )}
          </Column>
        </Row>
      </Container>
    )
  }
  const renderPlantPrefabDescriptionSection = () => {
    const plantPrefabDescriptionData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "home-description-module-1",
    })
    return (
      <Container customStyle={{ py: theme => theme.spacing.vertical.lg }}>
        <Row>
          <Column size={[12, 12, 4]}>
            <Text type="h2" customStyle={{ fontSize: [36, 36, 40] }}>
              {plantPrefabDescriptionData.title
                .split("\\n")
                .map((titleLine, index) => {
                  return (
                    <div
                      // sx={{ ...(index === 0 && { color: "primary" }) }}
                      key={index}
                    >
                      {titleLine}
                    </div>
                  )
                })}
            </Text>
          </Column>
          <Column
            customStyle={{
              mt: theme => [
                theme.spacing.vertical.md,
                theme.spacing.vertical.md,
                0,
              ],
            }}
            size={[12, 12, 8]}
          >
            <Text customStyle={{fontSize:17}}>
              {renderLineBreak(
                plantPrefabDescriptionData.description.description
              )}
            </Text>
            {/* <div sx={{ mt: theme => theme.spacing.vertical.md }}>
              <CTA
                link={plantPrefabDescriptionData.ctaDestination}
                type="secondary"
              >
                {plantPrefabDescriptionData.ctaTitle}
              </CTA>
            </div> */}
          </Column>
          {/* {plantPrefabDescriptionData.desktopImage ? (
            <Row
              size={[12, 12, 12]}
              customStyle={{
                mx: theme => theme.spacing.vertical.md,
                gap: theme => theme.spacing.vertical.sm,
              }}
            >
              <Column
                customStyle={{
                  mt: theme => theme.spacing.vertical.md,
                  border: "3px solid grey",
                  padding: "25px",
                  borderRadius: "10px",
                }}
                size={[12, 12, 5]}
              >
                <img
                  src={plantPrefabDescriptionData.desktopImage.file.url}
                  sx={{
                    mb: theme => [
                      theme.spacing.vertical.sm,
                      theme.spacing.vertical.sm,
                      0,
                    ],
                  }}
                  alt=""
                />
              </Column>
              <Column
                customStyle={{
                  mt: theme => theme.spacing.vertical.md,
                  border: "3px solid grey",
                  padding: "25px",
                  borderRadius: "10px",
                }}
                size={[12, 12, 5]}
              >
                <img
                  src={plantPrefabDescriptionData.desktopImage.file.url}
                  sx={{
                    mb: theme => [
                      theme.spacing.vertical.sm,
                      theme.spacing.vertical.sm,
                      0,
                    ],
                  }}
                  alt=""
                />
              </Column>
            </Row>
          ) : null} */}
        </Row>
      </Container>
    )
  }
  const renderBenefitsSection = () => {
    const benefitsSectionData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "home-benefits-module",
    })

    return (
      <div
        sx={{
          backgroundColor: theme => theme.colors.backgrounds.primary,
          py: theme => theme.spacing.vertical.lg,
        }}
      >
        <Container>
          <Row>
            {benefitsSectionData.contentCards.map((contentCard, index) => (
              <Column
                customStyle={{
                  mb: theme => ["8px", "8px", 0],
                  ":last-child": { mb: 0 },
                }}
                key={kebabCase(contentCard.title)}
                size={[12, 12, null]}
              >
                <ScrollAnimation
                  type="fadeIn"
                  delay={index * 0.15}
                  duration={1}
                  distance={20}
                >
                  <ProjectCard
                    background={mobileImageSelector(
                      get(contentCard, "desktopImage.file.url"),
                      get(contentCard, "mobileImage.file.url")
                    )}
                    text={[contentCard.title]}
                    sx={{ pb: ["50%", "50%", "160%"], height: 0 }}
                    // link={{
                    //   to: `/advantages`,
                    //   type: "internal",
                    // }}
                  />
                </ScrollAnimation>
              </Column>
            ))}
          </Row>
          <Row customStyle={{ mt: theme => theme.spacing.vertical.lg }}>
            <Column size={[12, 12, 5]}>
              <Text type="h2" customStyle={{ fontSize: [36, 36, 40] }}>
                {renderLineBreak(benefitsSectionData.title)}
              </Text>
            </Column>
            <Column size={[12, 12, 4]}>
              <Text
                customStyle={{
                  mt: theme => [
                    theme.spacing.vertical.sm,
                    theme.spacing.vertical.sm,
                    0,
                  ],
                  fontSize:17
                }}
              >
                {renderLineBreak(benefitsSectionData.description.description)}
              </Text>
              {/* <div sx={{ mt: theme => [20, 20, theme.spacing.vertical.md] }}>
                <CTA link={benefitsSectionData.ctaDestination} type="secondary">
                  {benefitsSectionData.ctaTitle}
                </CTA>
              </div> */}
            </Column>
          </Row>
        </Container>
      </div>
    )
  }
  const renderAudiencesSection = () => {
    const audiencesSectionData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "home-audiences-module",
    })

    return (
      <Container
        customStyle={{
          py: theme => [100, 100, theme.spacing.vertical.xl],
        }}
      >
        <Row>
          <Column>
            <Text
              customStyle={{
                fontSize: [36, 36, 6],
                maxWidth: ["360px", "360px", "none"],
                textAlign: ["left", "left", "center"],
              }}
              type="h2"
            >
              {audiencesSectionData.title}
            </Text>
          </Column>
        </Row>
        <Row
          customStyle={{
            justifyContent: "center",
            pt: theme => [
              theme.spacing.vertical.md,
              theme.spacing.vertical.md,
              theme.spacing.vertical.lg,
            ],
          }}
        >
          {audiencesSectionData.contentCards.map((card, index) => (
            <Column
              customStyle={{
                mb: theme => [
                  theme.spacing.vertical.sm,
                  theme.spacing.vertical.sm,
                  0,
                ],
                ":last-child": { mb: 0 },
              }}
              size={[12, 12, 4, 3]}
              key={kebabCase(card.title)}
            >
              <ScrollAnimation
                type="fadeIn"
                delay={index * 0.15}
                duration={1}
                distance={10}
              >
                <Flex
                  sx={{
                    alignItems: ["flex-start", "flex-start", "center"],
                    flexDirection: ["row", "row", "column"],
                    height: "100%",
                    justifyContent: ["flex-start", "flex-start", "center"],
                  }}
                >
                  <div
                    sx={{
                      backgroundImage: `url(${card.desktopImage.file.url})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      height: 70,
                      width: 70,
                    }}
                  />
                  <div
                    sx={{
                      ml: [4, 4, 0],
                      textAlign: ["left", "left", "center"],
                    }}
                  >
                    <Text
                      customStyle={{
                        fontSize: [3, 3, 5],
                        mt: theme => [0, 0, theme.spacing.vertical.sm],
                      }}
                      variant="h3"
                    >
                      {card.title}
                    </Text>
                    <div
                      sx={{ mt: theme => [2, 2, theme.spacing.vertical.sm] }}
                    >
                      <CTA
                        link={card.ctaDestination.ctaDestination}
                        linkType="internal"
                        type="secondary"
                      >
                        {card.ctaTitle}
                      </CTA>
                    </div>
                  </div>
                </Flex>
              </ScrollAnimation>
            </Column>
          ))}
        </Row>
      </Container>
    )
  }
  const renderFeaturedProjectsSection = () => {
    let featuredProjectsSectionData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "home-featured-projects-module",
    })

    let contentCards = featuredProjectsSectionData.contentCards.filter((card,index)=>{
      if(index<4) return card
    })
    featuredProjectsSectionData = {...featuredProjectsSectionData, contentCards:contentCards}

    return (
      <React.Fragment>
        <Container>
          <Row>
            <Column>
              <Text type="h2" customStyle={{ fontSize: [36, 36, 40] }}>
                {featuredProjectsSectionData.title}
              </Text>
              <div
                sx={{
                  mt: theme => [30, theme.spacing.vertical.md],
                  mb: theme => [50, theme.spacing.vertical.lg],
                }}
              >
                <CTA
                  link={featuredProjectsSectionData.ctaDestination}
                  type="secondary"
                >
                  {featuredProjectsSectionData.ctaTitle}
                </CTA>
              </div>
            </Column>
          </Row>
        </Container>
        <ScrollAnimation type="fadeIn" duration={1} distance={0}>
          <FeaturedProjects data={featuredProjectsSectionData} />
        </ScrollAnimation>
      </React.Fragment>
    )
  }
  const renderAwardsFeaturedInSection = () => {
    const awardsSectionData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "home-awards-module",
    })
    const featuredInSectionData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "home-featured-in-module",
    })
    const renderSection = (rowData, type) => (
      <React.Fragment>
        <Row
          customStyle={{
            mb: theme => [20, 20, theme.spacing.vertical.md],
          }}
        >
          <Column>
            <Text
              type="h2"
              customStyle={{
                textAlign: ["left", "left", "left", "center"],
                fontSize: [36, 36, 40],
              }}
            >
              {rowData.title}
            </Text>
          </Column>
        </Row>
        <Row
          customStyle={{
            alignItems: "center",
            mb: theme => theme.spacing.vertical.lg,
            ":last-child": { mb: 0 },
          }}
        >
          {rowData.contentCards.map((columnData, index) => (
            <Column
              customStyle={{
                alignItems:
                  type !== "secondary"
                    ? ["flex-start", "flex-start", "flex-start", "center"]
                    : "center",
                mb: [
                  theme => theme.spacing.vertical.sm,
                  theme => theme.spacing.vertical.sm,
                  theme => theme.spacing.vertical.sm,
                  0,
                ],
                ":last-child": { mb: type === "secondary" ? [null, 0] : 0 },
              }}
              size={type === "secondary" ? [4, 4, 4, null] : [12, 6, 6, null]}
              key={index}
            >
              <ScrollAnimation
                type="fadeIn"
                delay={index * 0.1}
                duration={0.5}
                distance={5}
              >
                <Flex sx={{ alignItems: "center" }}>
                  <img
                    sx={{
                      maxWidth: type === "secondary" ? [100, 140] : 140,
                      maxHeight: type === "secondary" ? [50, null] : null,
                    }}
                    src={columnData.desktopImage.file.url}
                    alt=""
                  />
                  {columnData.title && (
                    <Text customStyle={{ fontWeight: "heading", ml: 3 }}>
                      {renderLineBreak(columnData.title)}
                    </Text>
                  )}
                </Flex>
              </ScrollAnimation>
            </Column>
          ))}
        </Row>
      </React.Fragment>
    )
    return (
      <Container
        customStyle={{
          pb: theme => theme.spacing.vertical.xl,
          pt: theme => theme.spacing.vertical.md,
        }}
      >
        {renderSection(awardsSectionData, "primary")}
        {renderSection(featuredInSectionData, "secondary")}
      </Container>
    )
  }
  const renderLivinghomesModelsSection = () => {
    const livinghomesModelsSectionData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "home-livinghomes-models-module",
    })

    let GalleryDesign
    if (data.allContentfulModelGallery.edges) {
      GalleryDesign = data.allContentfulModelGallery.edges.find(
        ele => ele.node.slug === "home-model-gallery"
      )
    }


    const renderContentCard = standardHome => (
      <>
        <ContentCard
          baths={standardHome.baths}
          beds={standardHome.beds}
          imageList={
            standardHome.images
              ? [standardHome.images[0]]
              : [standardHome.model[0].images[0]]
          }
          squareFootage={standardHome.squareFootage || standardHome.size}
          title={standardHome.name || standardHome.model.name}
          titleLink={{
            to: `/models/${standardHome.model.slug}`,
            type: "internal",
          }}
          key={standardHome.id}
        />
      </>
    )
    const getMobileCarouselContent = () => {
      const carouselContent = []

      // data.allContentfulModelVariation.edges.forEach(standardHome => {
      //   if (standardHome.node.images && carouselContent.length < 4) {
      //     carouselContent.push(renderContentCard(standardHome.node))
      //   }
      // })

      GalleryDesign.node.model.forEach(standardHome => {
        if (standardHome.images && carouselContent.length < 4) {
          carouselContent.push(renderContentCard(standardHome))
        }
      })

      return carouselContent
    }
    const getDesktopCarouselContent = () => {
      const carouselContent = []

      // chunk(
      //   data.allContentfulModelVariation.edges.filter(
      //     standardHome => standardHome.node.images
      //   ),
      //   2
      // ).forEach((standardHome, index) => {
      //   if (standardHome.length === 2) {
      //     const getColumnSize = columnSize => {
      //       return {
      //         flexBasis: `${columnSize * 100}%`,
      //         maxWidth: `${columnSize * 100}%`,
      //       }
      //     }

      //     carouselContent.push(
      //       <div
      //         sx={{ display: "flex", height: "100%" }}
      //         key={standardHome[0].node.id}
      //       >
      //         <div sx={getColumnSize(index % 2 === 0 ? 1 / 3 : 2 / 3)}>
      //           {renderContentCard(standardHome[0].node)}
      //         </div>
      //         <div
      //           sx={{
      //             pl: theme => theme.spacing.horizontal,
      //             ...getColumnSize(index % 2 === 0 ? 2 / 3 : 1 / 3),
      //           }}
      //         >
      //           {renderContentCard(standardHome[1].node)}
      //         </div>
      //       </div>
      //     )
      //   } else if (standardHome.length === 1 && standardHome[0].node.images) {
      //     carouselContent.push(renderContentCard(standardHome[0].node))
      //   }
      // })

      chunk(
        GalleryDesign.node.model.filter(standardHome => standardHome.images),
        2
      ).forEach((standardHome, index) => {
        if (standardHome.length === 2) {
          const getColumnSize = columnSize => {
            return {
              flexBasis: `${columnSize * 100}%`,
              maxWidth: `${columnSize * 100}%`,
            }
          }

          carouselContent.push(
            <div sx={{ display: "flex", height: "100%" }} key={standardHome.id}>
              <div sx={getColumnSize(index % 2 === 0 ? 1 / 3 : 2 / 3)}>
                {renderContentCard(standardHome[0])}
              </div>
              <div
                sx={{
                  pl: theme => theme.spacing.horizontal,
                  ...getColumnSize(index % 2 === 0 ? 2 / 3 : 1 / 3),
                }}
              >
                {renderContentCard(standardHome[1])}
              </div>
            </div>
          )
        } else if (standardHome.length === 1 && standardHome.images) {
          carouselContent.push(renderContentCard(standardHome[0]))
        }
      })

      return carouselContent
    }

    return (
      <Container customStyle={{ pb: theme => theme.spacing.vertical.sm }}>
        <Row>
          <Column
            customStyle={{
              mb: theme => [
                theme.spacing.vertical.sm,
                theme.spacing.vertical.sm,
                0,
              ],
              ":last-child": { mb: 0 },
            }}
          >
            <Text type="h2" customStyle={{ fontSize: [36, 36, 40] }}>
              {renderLineBreak(livinghomesModelsSectionData.title)}
            </Text>
          </Column>
          <Column size={[12, 12, 6]}>
            <Flex
              sx={{
                justifyContent: ["flex-start", "flex-start", "flex-end"],
                mt: theme => [theme.spacing.vertical.md],
              }}
            >
              <div>
                {livinghomesModelsSectionData.ctaTitle ? (
                  <CTA
                    link={livinghomesModelsSectionData.ctaDestination || "/"}
                    type={"secondary"}
                    linkType="internal"
                  >
                    {livinghomesModelsSectionData.ctaTitle}
                  </CTA>
                ) : null}
              </div>
            </Flex>
          </Column>
        </Row>
        <Row customStyle={{ mt: theme => theme.spacing.vertical.md }}>
          <Column customStyle={{ display: ["block", "block", "none"] }}>
            <Carousel type="quaternary">{getMobileCarouselContent()}</Carousel>
          </Column>
          <Column customStyle={{ display: ["none", "none", "block"] }}>
            <Carousel type="quaternary">{getDesktopCarouselContent()}</Carousel>
          </Column>
        </Row>
      </Container>
    )
  }
  const renderProjectFeaturesSection = () => {
    const eachProjectIsUniqueBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-each-project-is-unique",
    })

    return (
      <ProjectUnique data={eachProjectIsUniqueBladePatternData}></ProjectUnique>
    )
  }
  const renderToursEventsNews = () => {
    return (
      <ToursEventsNews
        customStyle={{ my: theme => theme.spacing.vertical.md }}
        events={data.eventBriteEvents.events}
        news={data.allContentfulNews.nodes}
      />
    )
  }

  const renderQuote = () => {
    if (
      !data.allContentfulBladePattern ||
      !data.allContentfulBladePattern.edges.length > 0 ||
      data.allContentfulBladePattern.edges[0].node.quote === null
    )
      return
    else {
      const bladePatternData = data.allContentfulBladePattern.edges[0].node

      return (
        <div
          sx={{
            backgroundColor: theme => theme.colors.backgrounds.primary,
            py: theme => theme.spacing.vertical.lg,
          }}
        >
          {bladePatternData.quote.map(quote => (
            <Quote name={quote.name} quote={quote} photo={quote.image} />
          ))}
        </div>
      )
    }
  }

  return (
    <Page data={data.contentfulPage}>
      {renderHeroSection()}
      {renderPlantPrefabDescriptionSection()}
      {/* {renderBenefitsSection()} */}
      {/* {renderAudiencesSection()} */}
      {/* <br/><br/>
      {renderFeaturedProjectsSection()} */}
      {renderAwardsFeaturedInSection()}
      {/* {renderLivinghomesModelsSection()} */}
      {/* {renderProjectFeaturesSection()}
      {renderQuote()} */}
      {/* renderToursEventsNews() */}
    </Page>
  )
}

export default IndexPage

export const query = graphql`
  query indexPageQuery {
    contentfulPage(slug: { eq: "why-prefab" }) {
      ...PageData
      ...BladePatternData
    }

    allContentfulModelGallery {
      edges {
        node {
          id
          title
          slug
          model {
            id
            name
            model {
              id
              slug
            }
            squareFootage
            baths
            beds
            images {
              fluid {
                src
              }
            }
          }
        }
      }
    }

    allContentfulModelVariation(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          baths
          beds
          id
          images {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
            id
            title
          }
          model {
            id
            slug
          }
          name
          squareFootage
        }
      }
    }

    allContentfulProject(limit: 6, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          slug
          projectImages {
            file {
              url
            }
            id
            title
          }
          projectLocation {
            projectLocation
          }
          projectTitle {
            projectTitle
          }
        }
      }
    }

    eventBriteEvents(events: { elemMatch: { name: { text: { ne: null } } } }) {
      events {
        url
        start {
          utc
          local
        }
        end {
          utc
          local
        }
        name {
          text
        }
      }
    }

    allContentfulNews(
      filter: {
        type: { eq: "In the news" }
        categoryInTheNewsOnly: { eq: "Articles & Reviews" }
      }
    ) {
      nodes {
        title
        teaser {
          teaser
        }
        link
        image {
          file {
            url
          }
          description
        }
        categoryInTheNewsOnly
        publicationTitle
        date
      }
    }
    allContentfulBladePattern(filter: { slug: { eq: "home-quote-module" } }) {
      edges {
        node {
          id
          name
          slug
          quote {
            name
            quote {
              json
            }
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
