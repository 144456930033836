import React from "react"

const getScrollY = () => {
  if (window.pageYOffset !== undefined) {
    return window.pageYOffset
  } else if (window.scrollTop !== undefined) {
    return window.scrollTop
  } else {
    return (
      document.documentElement ||
      document.body.parentNode ||
      document.body
    ).scrollTop
  }
}

export const useScrollYPosition = () => {
  const [scrollYPosition, setScrollYPosition] = React.useState(0)

  React.useEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    function scrollHandler() {
      const currentScrollY = getScrollY()

      setScrollYPosition(currentScrollY)
    }

    window.addEventListener("scroll", scrollHandler)

    return () => {
      window.removeEventListener("scroll", scrollHandler)
    }
  }, [])

  return scrollYPosition
}
