/** @jsx jsx */
import get from "lodash/get"
import React from "react"

import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import kebabCase from "lodash/kebabCase"
import { Flex } from "@theme-ui/components"
import ScrollAnimation_ from "react-animate-on-scroll"

const ScrollAnimation = ({ children, delay, duration, distance, type }) => (
  <ScrollAnimation_
    animateIn="fadeIn"
    animateOnce={true}
    delay={delay && delay * 1000}
    duration={duration ?? 1}
    sx={{
      "&.fadeIn": {
        animation: "fadeIn 1s",
        opacity: 1,
      },
      "&.fadeOut": {
        animation: "fadeOut 1s",
        opacity: 0,
      },
      top: 0,
      position: "relative",
      "@keyframes fadeIn": {
        from: { transform: `translateY(${distance ?? 20}px)`, opacity: 0 },
        to: { transform: "translateY(0px)", opacity: 1 },
      },
      "@keyframes fadeOut": {
        from: { transform: "translateY(0px)", opacity: 1 },
        to: { transform: `translateY(${distance ?? 20}px)`, opacity: 0 },
      },
    }}
  >
    {children}
  </ScrollAnimation_>
)

export default ScrollAnimation
